<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? '编辑产品分类' : '新增产品分类' }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="12">
            <a-col :span="24" :md="24">
              <a-form-model-item prop="name" label="分类名称">
                <a-input v-model="form.name"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="24">
              <a-form-model-item prop="pid" label="上级分类">
                <a-select v-model="form.pid" style="width: 100%" :allowClear="true" :disabled="!!form.id">
                  <a-select-option v-for="item in categories" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="24">
              <a-form-model-item prop="is_active" label="状态">
                <a-switch
                    checked-children="激活"
                    un-checked-children="冻结"
                    v-model="is_active"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="24">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-divider orientation="left" id="beginning-inventory">
              期初库存
            </a-divider>
            <a-col :span="24" :md="24">
              <a-table
                  rowkey="id"
                  :columns="columns"
                  :data-source="warehouseItems"
                  :loading="loading"
                  :pagination="false"
                  style="width: 100%">
                <div slot="initial_quantity" slot-scope="value, item">
                  <div v-if="!!form.enable_batch_control">
                    <a-input :value="item.initial_quantity" disabled style="width:75%;"/>
                    <a-button @click="chooseBatch(item)">批</a-button>
                  </div>
                  <a-input-number v-else
                                  :value="item.initial_quantity"
                                  :min="1"
                                  @change="(value) => changeQuantity(value, item)"/>
                </div>
              </a-table>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-divider orientation="left" id="inventory-warning">
              库存预警
            </a-divider>
            <a-col :span="24" :md="24">
              <a-form-model-item prop="enable_inventory_warning" label="启用库存警告">
                <a-switch
                    checked-children="启用"
                    un-checked-children="禁用"
                    v-model="form.enable_inventory_warning"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="24" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_upper" label="库存上限">
                <a-input-number v-model="form.inventory_upper" style="width: 100%"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="24" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_lower" label="库存下限">
                <a-input-number v-model="form.inventory_lower" style="width: 100%"/>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {goodsClassificationCreate, goodsClassificationUpdate} from '@/api/goods'

export default {
  name: 'FormModal',
  props: ['visible', 'form', 'categories', 'warehouseItems'],
  model: {prop: 'visible', event: 'cancel'},
  data() {
    return {
      rules: {
        name: [{required: true, message: '请输入分类名称', trigger: 'change'},
          {max: 64, message: "超出最大长度 (64)", trigger: "change"},]
      },
      loading: false,

      columns: [
        {
          title: "仓库",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "初始库存",
          dataIndex: "initial_quantity",
          key: "initial_quantity",
          scopedSlots: {customRender: "initial_quantity"},
        },
      ],

      is_active: this.form.id ?? true
    };
  },
  methods: {
    confirm() {
      let inventory_items = this.warehouseItems.map(item => {
        return {
          warehouse: item.id,
          initial_quantity: item.initial_quantity,
          batch_items: this.form.enable_batch_control ? item.batch_items : []
        }
      })
      let formatData = {
        ...this.form,
        ...{
          inventory_items
        }
      }
      console.log(formatData)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? goodsClassificationUpdate : goodsClassificationCreate;
          formatData.pid = this.form.pid ?? 0
          func(formatData).then(data => {
            this.$message.success(this.form.id ? '修改成功' : '新增成功');
            this.$emit(this.form.id ? 'update' : 'create', data);
            this.cancel();
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },
    cancel() {
      this.$emit('cancel', false);
      this.$refs.form.resetFields();
    },
    changeQuantity(value, item) {
      item['initial_quantity'] = value || 0;
      this.warehouseItems = this.$functions.replaceItem(this.warehouseItems, item);
    },
  },
}
</script>

<style scoped>
</style>
